<script>
export default {
  template: '<template></template>',
}
</script>

<style lang="scss">
@import '../../AboutUs/AboutUs';
@import '../../AboutUs/AboutSection';
@import '../../ContactBlock/ContactBlock';
@import '../../CompanyText/CompanyText';
@import '../../CompanyDealers/CompanyDealers';
@import '../../IconifiedContentCard/IconifiedContentCard';
@import '../../AccordionPanel/AccordionPanel';
@import '../../AccordionTable/AccordionTable';
@import '../../AccordionContent/AccordionContent';
@import '../../CompanyLogo/CompanyLogo';
@import '../../Quote/Quote';
@import './CompanyDetail';
@import '../../../styles/CompanyCard';
@import '../../FacebookPanel/FacebookPanel';
</style>
